.card.card-hover {
  transition: background-color .2s;

  &:hover {
    background-color: aliceblue;
  }
}

.table-no-padding {
  th,
  td {
      padding: 0.25rem 0;
  }
}
