$primary: #FAED33;
$secondary: rgb(31, 35, 38);
$border-color: #E3EBF6;

$active-color: $primary;

$nav-row-item-border-color-active: $primary;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~@bootstrap-extended-style/bootstrap-extended-style/index";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

$sidebar-link-color: rgba(33, 37, 41, .6);
$sidebar-link-color-active: rgba(255, 255, 255, 1);
$sidebar-link-color-hover: rgba(33, 37, 41, .8);
$sidebar-link-color-active-hover: rgba(255, 255, 255, .8);
$sidebar-link-active-background-color: rgba(31, 35, 38, 1);

@import "~@zandor300/backoffice-framework/lib/scss/backoffice-framework";

@import "~mapbox-gl/dist/mapbox-gl.css";

@import "card";
@import "event-design";
@import "mapbox";
@import "navbar";

@import "~react-loading-skeleton/dist/skeleton.css";

$banner-image: url("/img/banner.jpg");
$login-background-color1: rgba(250, 237, 51, 0.8);
$login-background-color2: rgba(185, 171, 11, 0.8);
$sidebar-background-color1: rgba(250, 237, 51, 0.9);
$sidebar-background-color2: rgba(250, 237, 51, 0.8);

.login-background {
  background-image: linear-gradient(to bottom right, $login-background-color1, $login-background-color2), $banner-image !important;
}
.sidebar {
  background-image: linear-gradient(to bottom right, $sidebar-background-color1, $sidebar-background-color2), $banner-image !important;
}

.badge-primary {
  color: $secondary;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $secondary !important;
  color: white;
}

.pointer-cursor {
  cursor: pointer;
}

// Custom Checkbox check color
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.navbar-admin {
  padding-left: 1rem;
}
