.ride-editor-modal {
  .modal-fullscreen {
    height: 100vh;
    .modal-header {
      padding-top: 12px;
      padding-bottom: 12px;
      .pretitle {
        font-size: 0.625rem;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        color: #95aac9;
        margin: 0;
      }
      .title {
        font-size: 1.75rem;
        margin: 0;
        line-height: 1.2;
      }
    }
    .modal-body {
      min-height: 0; // Fix for flexbox overflow bug
    }
  }
}

.mapbox-debug {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  border-radius: 4px;
}
.mapbox-coordinates-debug {
  @extend .mapbox-debug;
  top: 0;
  right: 0;
  margin: 12px 50px 12px 12px;
}
.mapbox-history-debug {
  @extend .mapbox-debug;
  bottom: 0;
  right: 0;
  margin: 12px 12px 32px 12px;
}
.mapbox-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mapbox-error-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 500;
  padding: 15px;
  display: flex;
  justify-content: center;
  .error-container {
    max-width: 600px;
  }
}
.mapbox-modal-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 100%;

  .mapbox-modal-sidebar-content {
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 0;
    left: 0;

    flex-grow: 1;
    width: 100%;
    height: 100%;
    min-height: 0; // Fix for flexbox overflow bug

    .mapbox-modal-sidebar-scroll {
      flex-grow: 1;
      padding: 15px 15px 50px;
      overflow-y: auto;
      min-height: 0; // Fix for flexbox overflow bug

      .card.card-hover {
        margin-bottom: 0.5rem;
        &:last-child {
          margin-bottom: 0;
        }
        .card-body {
          padding: 0.5rem 1rem;
        }
      }
    }
  }

  .mapbox-modal-sidebar-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;

    background-color: rgba(0, 0, 0, 0.65);
    color: white;
    font-size: 1.3rem;
    font-weight: 500;
  }
}
.mapbox-modal-container {
  display: flex;
  width: 100%;
  height: 100%;
  .mapbox-map-container {
    width: 100%;
    height: 100%;
  }
}

.waypoint-delete-backdrop {
  z-index: 1050;
}

// Custom Scrollbar

.mapbox-modal-sidebar-scroll::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.mapbox-modal-sidebar-scroll::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
.mapbox-modal-sidebar-scroll::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.mapbox-modal-sidebar-scroll::-webkit-scrollbar-button {
  display:none;
}

.mapboxgl-ctrl-group {
  box-shadow: none !important;
  border: #E3EBF6 1px solid;
  button + button {
    border-color: #E3EBF6;
  }
}

@keyframes arrow-animation-down {
  0% {
    transform: translateY(0);
    opacity: 0.4
  }
  100% {
    transform: translateY(0.2em);
    opacity: 0.9
  }
}
.arrow-animation {
  transform-origin: 50% 50%;
  animation: arrow-animation-down 1s infinite alternate ease-in-out;
}
