.navbar-only {
  transition: padding-left $sidebar-transition-speed $sidebar-transition-curve;
  padding-left: 30px + $sidebar-width;
  &.mobile {
    padding-left: 1rem;
  }
  &.sidebar-collapsed {
    padding-left: 30px + $sidebar-width-collapsed;
  }
}
