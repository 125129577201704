@include media-breakpoint-up(lg) {
  .event-design-column {
    width: 300px;
  }
}

.event-design-demo-container {
  @extend .rounded;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  height: 450px;

  background-size: cover;
  background-position: center;
  padding: 2.5rem;

  .description-container {
    display: flex;
    align-items: center;
    width: 100%;

    p {
      color: white;
      font-size: 0.7rem;
      text-align: left;
      margin: 0;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
